







































































































































import TimeButtonPicker from "@/components/TimeButtonPicker.vue"
import DateButtonPicker from "@/components/DateButtonPicker.vue"
import EventService from "@/services/EventService"
import {MultipleItem, SingleItem} from "@/handlers/interfaces/ContentUI"
import {Component, Ref, Vue} from "vue-property-decorator"
import SnackbarModule from "@/store/SnackbarModule"
import '@toast-ui/editor/dist/toastui-editor.css'
import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import {Editor} from '@toast-ui/vue-editor'
import Rules from "@/services/tool/Rules"
import Handler from "@/handlers/Handler"
import Event, {EventStatus} from "@/models/Event"
import {DateTime} from "luxon"
import PotentialClientService from "@/services/PotentialClientService"
import PotentialClient from "@/models/PotentialClient"

@Component({components: {DateButtonPicker, TimeButtonPicker, Editor}})
export default class CreateActivityView extends Vue {

    @Ref() readonly form!: HTMLFormElement
    lang: any = getModule(LangModule).lang
    loading: boolean = false

    get rules() { return Rules }

    event: SingleItem<Event> = {item: new Event()}
    potentialClients: MultipleItem<PotentialClient> = {items: [], totalItems: 0}
    selectedPotentialClients: PotentialClient[] = []

    date = DateTime.now().toUTC()
    startsAtDate = this.date.toFormat("yyyy-MM-dd")
    endsAtDate = this.date.toFormat("yyyy-MM-dd")
    startsAtHour = this.date.toFormat("HH:mm")
    endsAtHour = this.date.toFormat("HH:mm")

    get startsAt() { return `${this.startsAtDate} ${this.startsAtHour}:00` }

    get endsAt() { return `${this.endsAtDate} ${this.endsAtHour}:00` }

    get isEdit() { return (this.$route.name == "Editar Actividad") }
    get isFinishable() { return (this.event.item.status == EventStatus.IN_PROGRESS) || (this.event.item.status == EventStatus.WAITING) }

    editorOptions = {hideModeSwitch: true}

    async created() {
        if (this.isEdit) {
            if (!isNaN(Number(this.$route.params.id))) {
                await this.getEvent()
            } else {
                this.onErrorRetrieving()
            }
        }
        await this.refresh()
    }

    async refresh() {
        try {
            await Handler.getItems(this, this.potentialClients, () =>
                PotentialClientService.getPotentialClients2(0, 8, null, true)
            )
        } catch (e) {
            console.log(e)
        }
    }

    async getEvent() {
        try {
            await Handler.getItem(this, this.event, () => EventService.getEvent(Number(this.$route.params.id)))
            // @ts-ignore
            this.$refs.toastuiEditor.invoke('setMarkdown', this.event.item.description)
            this.startsAtDate = this.event.item.startsAt!!.toFormat("yyyy-MM-dd")
            this.endsAtDate = this.event.item.endsAt!!.toFormat("yyyy-MM-dd")
            this.startsAtHour = this.event.item.startsAt!!.toFormat("HH:mm")
            this.endsAtHour = this.event.item.endsAt!!.toFormat("HH:mm")
        } catch (e) {
            this.onErrorRetrieving()
        }
    }

    async createEvent() {
        if (this.form.validate()) {
            try {
                await EventService.createEvent(
                    this.event.item.title!!, this.event.item.description!!, this.startsAt, this.endsAt, this.event.item.potentialClients!!,
                    this.event.item.isAllDay!!, this.event.item.notifyByMail!!
                )
                this.onChangeSaved()
            } catch (e) {
                console.log(e)
            }
        }
    }

    async cancel() {
        try {
            await Handler.getItem(this, this.event, () => EventService.cancelEvent(Number(this.$route.params.id)))
            getModule(SnackbarModule).makeToast("El evento ha sido cancelado.")
            await this.$router.push("/activities")
        } catch (e) {
            console.log(e)
        }
    }


    async finish() {
        await Handler.getItem(this, this.event, () => EventService.finish(Number(this.$route.params.id)))
    }

    async save() {
        try {
            await Handler.getItem(this, this.event, () =>
                EventService.updateEvent(Number(this.$route.params.id), this.event.item, this.startsAt, this.endsAt)
            )
            await this.$router.push("/activities")
            getModule(SnackbarModule).makeToast("El evento se ha guardado con exito.")
        } catch (e) {
            console.log(e)
        }
    }

    onEditorChange() {  // @ts-ignore
        this.event.item.description = this.$refs.toastuiEditor.invoke('getMarkdown')
    }

    onErrorRetrieving() {
        getModule(SnackbarModule).makeToast("No se han podido obtener el evento.")
        this.$router.push("/activities")
    }

    onChangeSaved() {
        getModule(SnackbarModule).makeToast("El evento se ha guardado con exito.")
        this.$router.push("/activities")
    }
}
