import ConstantTool from "@/services/tool/ConstantTool"
import {getModule} from "vuex-module-decorators"
import SessionModule from "@/store/SessionModule"
import JsonTool from "@/services/tool/JsonTool"
import Event, {EventStatus} from "@/models/Event"
import axios from "axios"
import Response from "@/models/responses/Response";
import PotentialClient from "@/models/PotentialClient";


export enum NotifyType { EMAIL = "EMAIL", APPLICATION = "APPLICATION" }

export default class EventService {

    static async getEvent(id: number): Promise<Response<Event>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/event/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const event = JsonTool.jsonConvert.deserializeObject(response.data, Event)
            return Promise.resolve({ result: event })
        } catch (e) { return Promise.reject(e) }
    }

    static async findEvents(
        page: number, size: number, search: Nullable<string>, start: Nullable<string>, end: Nullable<string>, status: Nullable<EventStatus>
    ): Promise<Response<Event[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/event", {
                headers: {Authorization: getModule(SessionModule).session.token},
                params: { page, size, search, start, end, status }
            })
            const event = JsonTool.jsonConvert.deserializeArray(response.data, Event)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: event, xTotalCount })
        } catch (e) { return Promise.reject(e) }
    }

    static async createEvent(
        title: string, description: string, startsAt: string, endsAt: string, potentialClients: PotentialClient[],
        isAllDay: Nullable<boolean>, notifyByMail: boolean
    ): Promise<Response<Event>> {
        try {
            const potentialClientIds = potentialClients.map(item => item.id)
            const response = await axios.post(
                ConstantTool.BASE_URL + "/api/event",
                { title, description, startsAt, endsAt, potentialClientIds, isAllDay, notifyByMail },
                { headers: { Authorization: getModule(SessionModule).session.token } }
                )
            const event = JsonTool.jsonConvert.deserializeObject(response.data, Event)
            return Promise.resolve({ result: event })
        } catch (e) { return Promise.reject(e) }
    }


    static async updateEvent(id: number, request: Event, startsAt: Nullable<string>, endsAt: Nullable<string>): Promise<Response<Event>> {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + "/api/event/" + id, request, {
                    headers: { Authorization: getModule(SessionModule).session.token },
                    params: { startsAt, endsAt }
            })
            const event = JsonTool.jsonConvert.deserializeObject(response.data, Event)
            return Promise.resolve({ result: event })
        } catch (e) { return Promise.reject(e) }
    }

    static async cancelEvent(id: number): Promise<Response<Event>> {
        try {
            const response = await axios.delete(`${ConstantTool.BASE_URL}/api/event/${id}/cancel`, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const event = JsonTool.jsonConvert.deserializeObject(response.data, Event)
            return Promise.resolve({ result: event })
        } catch (e) { return Promise.reject(e) }
    }

    static async finish(id: number) {
        try {
            const response = await axios.patch(`${ConstantTool.BASE_URL}/api/event/${id}/finish`, null,{
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const event = JsonTool.jsonConvert.deserializeObject(response.data, Event)
            return Promise.resolve({ result: event })
        } catch (e) { return Promise.reject(e) }
    }

}